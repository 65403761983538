import { IGraphqlQueryResult } from './IGraphqlQueryResult';

export type IPageItemQueryResult = IGraphqlQueryResult<{ allPageItem: any }, IPageItem>

export interface IPageItem{
    Header_x0020_image?: String;
    HeaderImageFile?: any;
    Headerimagealignment?: EHeaderImageAlignment;
    Headerimagealt?: string;
    Header_x0020_image_x0020_size?: EHeaderImageSize;
    CSS?: string;
    resolvedImage?: any;
    Title: string;
}

export enum EHeaderImageAlignment{
    Left="Left",
    Right="Right"
}

export enum EHeaderImageSize{
    Large="Large",
    Med="Medium",
    Small="Small"
}