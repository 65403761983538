import * as React from 'react';
import { useEffect, useState } from 'react';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { useStaticQuery, graphql } from 'gatsby';
import { IContentItemQueryResult } from '../interfaces/IContentItemQueryResult';
import * as WebBlocks from '../components/WebBlocks/WebBlocks';
import { WebBlock } from '../components/WebBlock/WebBlock';
import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import { EBackgroundImageAlignment, EHeaderImageAlignment, EHeaderImageSize, IPageItemQueryResult } from '../interfaces/IPageItemQueryResult';
import OverlayClip from '../assets/heroOverlayClip.asset.svg';
import { Redirect, Router } from '@reach/router';
import { Helmet } from 'react-helmet';

import * as styles from './Page.module.scss'
import * as globalStyles from '../globals.module.scss';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { AuthConfig } from '../services/auth.service';
import { SiteGlobalConfig } from '../site.config';

export interface IPagePageContext{
    id: string;
    originalId: string;
    title: string;
    slug: string;
    webBlocks: any[];
    isSecure: boolean;
}

export interface IPageProps{
    pageContext: IPagePageContext
    data: IContentItemQueryResult & IPageItemQueryResult;
    path?: string;
    params?: any;
}

const Page = (props: IPageProps) => {
    const { pageContext, data, params } = props;
    const { slug, title, webBlocks, id } = pageContext;
    const { allPageItem } = data;
    const { HeaderImageFile, Headerimagealignment, Headerimagealt, Header_x0020_image_x0020_size, CSS, resolvedImage, Title } = allPageItem.nodes[0];
    const [headerImage, setHeaderImage] = useState<IGatsbyImageData>();
    const { PRODUCTION_SITE_HOSTNAME } = SiteGlobalConfig;

    useEffect(() => {
        console.log(data);
    },[data]);

    useEffect(() => {
        console.log("GETTING HEADER IMAGE",allPageItem.nodes[0]);
        if(HeaderImageFile){
            const img = getImage(HeaderImageFile);
            setHeaderImage(img);
        }
    }, [HeaderImageFile]);
    
    // useEffect(() => {
    //     console.log("GETTING HEADER IMAGE",allPageItem.nodes[0]);
    //     if(resolvedImage && resolvedImage[0]){
    //         const img = getImage(resolvedImage[0]);
    //         console.log(img);
    //         setHeaderImage(img);
    //     }
    // },[resolvedImage]);
    
    // const headerImage = getImage(Header_x0020_image);

    const [base64Css, setBase64Css] = useState<string>(null);

    useEffect(() => {
        const Buf = window.Buffer !== undefined ?  window.Buffer : require('buffer').Buffer;
        if(CSS && Buf){
            const buf = Buf.from(CSS);
            const base64 = buf.toString('base64');
            setBase64Css(base64);
        }
    },[CSS]);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ Title }</title>
                <link rel="canonical" href={ `https://${PRODUCTION_SITE_HOSTNAME}${slug}`} />
            </Helmet>
            {
                headerImage && (
                    <div className={ styles.pageHeaderImage }>
                        <div className={ [styles.imageContainer, styles[`imageContainer${Header_x0020_image_x0020_size}`], (Headerimagealignment === EHeaderImageAlignment.Left ? styles.headerImageLeft : styles.headerImageRight)].join(" ") }>
                            <div className={ styles.imageHeightContainer }>
                                <GatsbyImage image={ headerImage } alt={ Headerimagealt } className={ styles.headerImage } />
                                <div className={ [ styles.logo , Header_x0020_image_x0020_size ? styles[`logo${Header_x0020_image_x0020_size}`] : styles.logoDefault].join(" ") }></div>
                            </div>
                            <OverlayClip />
                        </div>
                    </div>
                )
            }
            <section className={ [globalStyles.sectionGrid, 'page-section-grid'].join(" ") }>
                {
                    data.allContentItem.nodes.map((node) => {
                        const { ContentType, ...restOfProps } = node
                        return(
                            <WebBlock contentType={ ContentType } blockProps={ restOfProps } key={ node.id } params={ params } />
                        )
                    })
                }
            </section>
            {
                base64Css && (
                    <link rel="stylesheet" href={`data:text/css;base64,${base64Css}`} />
                )
            }
        </>
    )
}

const MSALPageWrapper = (props: IPageProps) => {
    const msalInstance = new PublicClientApplication(AuthConfig);
    const { pageContext, params } = props;
    const { isSecure } = pageContext || {};

    useEffect(() => {
        window.localStorage.setItem("pbovicgovau_post_login_redirect", window.location.pathname);
    }, [])

     return (
        <MsalProvider instance={ msalInstance }>
            {/* <Router basepath="/"> */}

            {
                // If logged in always try to render the page
            }
            <AuthenticatedTemplate>
                <Page { ...props }/>

            </AuthenticatedTemplate>
            {
                // If NOT logged in check if the user needs to be to view the page, if not render it otherwise redirect to the login page
            }
            <UnauthenticatedTemplate>
                {
                    isSecure !== true ? (
                        <Page { ...props } />
                        ) : (
                            <Redirect to="/secure/login" />
                            )
                        }
            </UnauthenticatedTemplate>
            {/* </Router> */}
        </MsalProvider>
    )
}

export const query = graphql`
    query getPageContentItems($originalId: String){
        allContentItem(filter: {PageLookupId: {eq: $originalId}}, sort: {fields: DisplayOrder}){
            nodes{
                id
                ContentType
                DisplayOrder
                ...ContactBlockFragment
                ...DownloadPanelBlockFragment
                ...HeroBlockFragment
                ...LargeGraphWithTextBlockFragment
                ...ParagraphCardBlockFragment
                ...PowerBIFrameBlockFragment
                ...SmallGraphWidgetAreaBlockFragment
                ...SmallTextWidgetBlockFragment
                ...TileBlockFragment
                ...TitleBlockFragment
                ...WorkListBlockFragment
                ...WebFormBlockFragment
                ...EmbeddedVideoBlockFragment
            }
        }
        allPageItem(filter:{ originalId: { eq: $originalId }}){
            nodes{
                HeaderImageFile{
                    absolutePath
                    childImageSharp{
                        gatsbyImageData(
                            height: 800
                            width: 1200
                            placeholder: BLURRED
                            formats: [AUTO]
                        )
                    }
                }
                Headerimagealignment
                Headerimagealt
                Header_x0020_image_x0020_size,
                CSS
                Title
            }
        }
    }
`;

export default MSALPageWrapper;
// export default Page;